<template>
  <div id="newsStoryContent">
    <p id="Text1">
      <!-- picE7A72540E99C5922E888CE1AF9CB2D6D.jpg -->
      <img class="rounded" style="height: 330px;" src="@/assets/news-network/lu-brought-creations-to-life-at-brick-con-1.jpg">
      <br><br>
      During the first weekend of October, at the popular LEGO® hobbyist event BrickCon in Seattle,
      we took the classic old brick into the future and brought creations to life in a whole
      different universe fairly new to the AFOLs (Adult Fans of LEGO®).
      <br><br>
      Surrounded by their creative building traditions and magnificent models, we added some
      virtual flavor, and showed off new ways to build and play with the brick online in the
      first ever LEGO MMOG (massively multiplayer online game).
      <br><br>
      Our LUPs (LEGO Universe Partners), or World Builders as they are also called, joined us
      to talk about their involvement with LEGO Universe, and why they continuously spend tons
      of their free time building and creating amazing game content for LEGO Universe -- as they
      say ‘’you never get too old to play with LEGO bricks’’.
      <br><br>
      They showed off their technical skills to a completely floored crowd by physically building
      the Dojo temple perched on top of the Great Tree in Forbidden Valley in-game. And for the
      first time, they also opened up the doors to the amazing new worlds they’ve created and
      brought to life in LEGO Universe.
      <br><br>
      <!-- pic25F2B595ABA77608049B67BA0B5A6CEE.jpg -->
      <img class="rounded" style="height: 330px;" src="@/assets/news-network/lu-brought-creations-to-life-at-brick-con-2.jpg">
      <br><br>
      <!-- picFDB7736ADBEA3C209484718DF203A826.jpg -->
      <img class="rounded" style="height: 330px;" src="@/assets/news-network/lu-brought-creations-to-life-at-brick-con-3.jpg">
    </p>
  </div>
</template>
